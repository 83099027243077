import { useState, useEffect } from "react";
import Http from "../../utils/Http";
import Cookies from "js-cookie";
import { useMutation, gql } from "@apollo/client";
import { useToast } from "@chakra-ui/react";
// const token = process.env.COOKIE_TOKEN;

const UseLogin = (submitForm, validate, result) => {
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  // const [isSubmitting, setIsSubmitting] = useState(false);

  const LOGIN = gql`
    mutation Mutation($email: String!, $password: String!) {
      login(email: $email, password: $password) {
        token
        email
        password
        branch {
          _id
        }
      }
    }
  `;
  const toast = useToast();
  const [doLogin, { loading }] = useMutation(LOGIN, {
    variables: values,
    update(proxy, result) {
      console.log(result);
      const {
        data: { login: userData },
      } = result;
      console.log(result?.data, "results");

      if (result?.errors?.length > 0) {
        submitForm(false);
      } else {
        Cookies.set("token", result.data.login.token);
        submitForm(true);
      }
    },

    onError(err) {
      toast({
        title: "Error",
        description: err.graphQLErrors?.map((el) => el.message),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    doLogin(values);
    // setErrors(validate(values));
  };

  // useEffect(() => {
  //   if (Object.keys(errors).length === 0 && isSubmitting) {
  //     submitForm();
  //   }
  // }, [errors]);

  return { handleChange, handleSubmit, values, errors };
};

export default UseLogin;
