import axios from "axios";
import Cookie from "js-cookie";

const api = process.env.REACT_APP_PUBLIC_API;

const token = Cookie.get("parfetts");

export const baseApi = api;

axios.defaults.baseURL = api;
axios.defaults.headers.common.Accept = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

if (token) {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
} else {
  axios.defaults.headers.common.Authorization = null;
}

export default axios;
