import React from "react";
import { Flex } from "@chakra-ui/react";
import LoginCard from "./LoginCard";
import "../../App.css";
import BGImage from "./login_bg.jpg";
const Login = ({ auth, setAuth, submitForm }) => {
  // #101010
  return (
    <Flex
      height='100vh'
      width='100vw'
      backgroundImage={BGImage}
      backgroundSize='cover'
      backgroundPosition={"center"}
      color='black'
      alignItems='center'
      justifyContent='center'
      overflow='hidden'
      position='relative'
      fontFamily='Roboto Condensed'
    >
      <LoginCard submitForm={submitForm} auth={auth} setAuth={setAuth} />
    </Flex>
  );
};

export default Login;
