export default function validateInfo(values) {
  let errors = {};

  // if (!values.username.trim()) {
  //   errors.username = "Username required";
  // }

  if (!values.email) {
    errors.email = "Email required";
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    console.log(values.email);
    errors.email = errors.message;
  }
  //  else if (values.email != result?.data?.login?.email) {
  //   errors.email = "User Not Found";
  // }

  if (!values.password) {
    errors.password = "Password is required";
  } else if (values.password.length < 6) {
    errors.password = "Password needs to be 6 characters or more";
  }

  return errors;
}
