import React, { useState, useEffect } from "react";
import { Button, Box, Image } from "@chakra-ui/react";
import Cookies from "js-cookie";
import { useQuery, gql, useLazyQuery } from "@apollo/client";

import { render } from "@testing-library/react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel from "react-responsive-carousel/lib/js/components/Carousel/index";

import jwt from "jsonwebtoken";

const CarouselContainer = ({ slides, submitForm }) => {
  const [branchId, setBranchId] = useState(null);

  const length = slides.length;
  const token = Cookies.get("token");

  const SLIDER_IMAGES = gql`
    query BranchById($id: MongoID!) {
      branchById(_id: $id) {
        name
        images
        interval
        transition
        animation
      }
    }
  `;

  const [getSlides, { loading, error, data }] = useLazyQuery(SLIDER_IMAGES);

  // const { loading, error, data } = useQuery(SLIDER_IMAGES, {
  //   variables: { id: branchId },
  // });

  const logout = () => {
    Cookies.remove("token");
    submitForm(false);
  };

  useEffect(() => {
    if (token) {
      const decodedToken = jwt.verify(token, process.env.REACT_APP_SECRET_KEY);
      setBranchId(decodedToken.branch);
    }
  }, [token]);

  useEffect(() => {
    if (branchId) {
      getSlides({
        variables: { id: branchId },
      });
    }
  }, [branchId]);

  return (
    <Box h='100vh' overflow={"hidden"} zIndex='10'>
      <Box
        position={"absolute"}
        top='0'
        right='0'
        bg='yellow'
        zIndex={12}
        opacity={0}
        transition='opacity 200ms linear'
        _hover={{ opacity: "1" }}
      >
        <Button onClick={() => logout()}>Log out</Button>
      </Box>

      {data?.branchById && (
        <Carousel
          autoPlay
          infiniteLoop
          interval={
            data?.branchById?.interval ? data?.branchById?.interval : "6000"
          }
          showArrows={false}
          showThumbs={false}
          showIndicators={false}
          stopOnHover={false}
          transitionTime={
            data?.branchById?.transition ? data?.branchById?.transition : "1000"
          }
          animationHandler={
            data?.branchById?.animation ? data?.branchById?.animation : "slide"
          }
        >
          {data?.branchById.images.map((slide, index) => (
            <Box
              height='100vh'
              w='100vw'
              backgroundImage={`url("${slide.url}")`}
              backgroundSize='cover'
              backgroundPosition={"center"}
              backgroundRepeat='no-repeat'
            >
              {" "}
            </Box>
          ))}
        </Carousel>
      )}
    </Box>
  );
};

export default CarouselContainer;
