import React, { useState } from "react";
import UseLogin from "./UseLogin";
import validateInfo from "../../utils/validateInfo";
import {
  Button,
  Flex,
  Input,
  FormControl,
  FormHelperText,
  useColorModeValue,
} from "@chakra-ui/react";

const LoginCard = ({ submitForm }) => {
  const { handleChange, handleSubmit, values, errors } = UseLogin(
    submitForm,
    validateInfo
  );
  const [showLogin, setShowLogin] = useState(false);

  const ErrorText = useColorModeValue("red", "red.600");
  const TextColor = useColorModeValue("black", "white");
  const UnderlineColor = useColorModeValue("black", "white");
  const handleKeyPress = (target) => {
    if (target.charCode === 13) {
      handleSubmit();
    }
  };
  return (
    <Flex
      direction='column'
      flexWrap='wrap'
      width='450px'
      alignItems='center'
      textAlign='center'
      justifyContent='center'
      noValidate
      borderRadius='5px'
      background={"rgba(225, 225, 225, 0.8)"}
      onSubmit={handleSubmit}
      className='form'
    >
      <Button
        position={"absolute"}
        top='20px'
        right='20px'
        onClick={() => setShowLogin(!showLogin)}
      >
        Login
      </Button>
      {showLogin && (
        <>
          <FormControl id='email' isRequired pt='10px'>
            <Input
              isInvalid={true}
              textColor={TextColor}
              focusBorderColor='crimson'
              errorBorderColor={UnderlineColor}
              width='80%'
              type='password'
              name='email'
              variant='flushed'
              placeholder='email'
              value={values.email}
              onChange={handleChange}
              _placeholder={{ color: "gray.500" }}
              size='lg'
              onKeyPress={handleKeyPress}
            />
            <FormHelperText color={ErrorText} fontSize='15px' m={3}>
              {errors.email && <p>{errors.email}</p>}
            </FormHelperText>
          </FormControl>
          <FormControl>
            <Input
              width='80%'
              _placeholder={{ color: "gray.500" }}
              textColor={TextColor}
              type='password'
              name='password'
              placeholder='password'
              size='lg'
              focusBorderColor='crimson'
              variant='flushed'
              isInvalid={true}
              errorBorderColor={UnderlineColor}
              value={values.password}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
            />
            <FormHelperText color={ErrorText} fontSize='15px' m={3}>
              {errors.password && <p>{errors.password}</p>}
            </FormHelperText>
          </FormControl>

          <Button
            background='transparent'
            width='80%'
            fontSize='1.2rem'
            color='black'
            textTransform='uppercase'
            borderRadius={1}
            type='submit'
            onClick={handleSubmit}
            mb='10px'
            mt='5px'
          >
            Log In
          </Button>
        </>
      )}
    </Flex>
  );
};

export default LoginCard;
