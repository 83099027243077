import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import { ChakraProvider } from "@chakra-ui/react";
import Login from "./components/Login/Login";
import Carousel from "./components/Carousel";
import { CarouselData } from "./components/CarouselData";
import AppContext from "./AppContext";
import { ApolloProvider } from "@apollo/client";
import client from "./client";
import "dotenv/config";
const App = ({ auth, setAuth }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  //check for cookie
  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      setIsSubmitted(token);
    }
    return () => {};
  }, []);

  return (
    <ApolloProvider client={client}>
      <AppContext.Provider value={{ auth, setAuth, setIsSubmitted }}>
        <ChakraProvider>
          <Router>
            {!isSubmitted ? (
              <Route
                path
                to='/login'
                exact
                component={() => {
                  return (
                    <Login
                      submitForm={setIsSubmitted}
                      auth={auth}
                      setAuth={setAuth}
                    />
                  );
                }}
              />
            ) : (
              <Route
                path
                to='/carousel'
                exact
                component={() => {
                  return (
                    <Carousel
                      submitForm={setIsSubmitted}
                      auth={auth}
                      setAuth={setAuth}
                      slides={CarouselData} //where we pull slides
                    />
                  );
                }}
              />
            )}
          </Router>
        </ChakraProvider>
      </AppContext.Provider>
    </ApolloProvider>
  );
};

export default App;
